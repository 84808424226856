
import {Options, Vue} from 'vue-class-component';
import Type3Select from "@/components/top/Type3Select.vue"
import jin_du_data_list from "@/views/user/jin_du_public/list/jin_du_data_list.vue";
import publicApi from "@/api/dataList/publicApi"
import userApi from "@/api/dataList/userApi"
import basis from "@/components/class/basis";
import din_shi from "@/api/din_shi";
import XiaLa from "@/components/page/XiaLa.vue";
import {Dialog} from "vant";

@Options({
  components: {Type3Select, jin_du_data_list, XiaLa},
  mixins:[basis],
  data() {
    return {
      userInfo: {},
      keHuTel: '',
      selectList: [
        {text: '进行中项目', value: '= 10'},
        {text: '历史项目信息', value: '!= 10'},
      ],
      where:{
        type:'',
        keyword:''
      }
    }
  },
  created(){
    this.where.type = this.selectList[0].value
  },
  mounted() {

    publicApi.getKeHuTel().then(a => {
      this.keHuTel = a
    })
    userApi.jinDuUser().then(da => {
      this.userInfo = da
      if (!this.userInfo.mobile) {
        Dialog.alert({
          title: '提示',
          message: '请绑定手机号',
        }).then(()=>{
          this.$router.push({name: 'user_jin_du_set_mobile'})
        })
      }
    })
  },
  methods:{
    changeKey(val){
      this.where.keyword = val
      this.$refs.xiaLa.reset()
    },
    selectType(da){
      this.where.type=da;
      this.$refs.xiaLa.reset()
    }
  }
})

export default class jin_du_index extends Vue {

}
