<template>
  <div class="xia-la">
    <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <slot name="list" :list="list"></slot>
      <!--      <template v-slot:default="list"></template>-->
    </van-list>
  </div>
</template>

<script>
import {Options, Vue} from 'vue-class-component';
import axios from "@/api/http.js"
import toJSONString from "xe-utils/toJSONString";
import toStringJSON from "xe-utils/toStringJSON";
import to from "await-to-js";
import {get} from "xe-utils";

export default {
  props: {
    //配置每页数量
    size: {
      type: Number,
      default() {
        return 20
      }
    },
    // 配置get地址
    getUrl: {
      type: String,
      default() {
        return ''
      }
    },
    axiosType: {
      type: String,
      default() {
        return 'get'
      }
    },
    // 配置查询条件
    where: {
      type: Object,
      default() {
        return {}
      }
    }

  },
  data() {
    return {
      list: [],
      error: false,
      loading: false,
      loadLength: 1,//加载几次
      finished: false,
      thisData: [],

    }
  },
  methods: {
    async onLoad() {
      if (this.loading) return
      this.loading = true

      //配置查询条件
      let where = toStringJSON(toJSONString(this.where))
      where.page = this.loadLength
      where.pageSize = this.size
      let getData = []
      let error = {}
      if (this.axiosType === 'get') {
        const [e, da] = await to(axios.get(this.getUrl, {
          params: where
        }))
        getData = da
        error = e
      } else {
        const [e, da] = await to(axios.post(this.getUrl, where))
        getData = da
        error = e
      }
      this.loadLength++
      if (error) {
        this.error = true
        console.log('错误', error)
        return
      }


      if (getData.code == 1) {
        if (typeof getData.data.total != "undefined") {
          this.thisData = getData.data.data
        } else {
          this.thisData = getData.data
        }

        if (this.thisData.length < this.size) {
          this.finished = true; //加载到底
        }
        this.list = this.list.concat(this.thisData)
        this.$emit('update:list', this.list)
        this.$emit('addData', getData)
        this.loading = false
      }

    },
    reset() {
      this.loadLength = 1
      this.list = []
      this.loading = false
      this.finished = false
      this.error = false
      this.onLoad()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.xia-la .van-list {
  border-bottom: 0
}

</style>
