
import {Options, Vue} from 'vue-class-component';
import basis from "@/components/class/basis";

@Options({
  mixins: [basis],
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    }
  }
})

export default class jin_du_data_list extends Vue {

}
